import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useTranslation } from 'lib/i18n';

import { mediaSelector } from 'client/helpers/style-utils';

import { Button } from 'client/components/shared/Button/Button';

import { getCurrentUser } from 'components/users/WithCurrentUser/WithCurrentUser';
import Userpic from 'components/shared/Userpic/Userpic';
import LogoIcon from 'components/v2/shared/svg/LogoWithTextIcon.svg';

import { Container } from '../styles';

const StyledContainer = styled(Container).attrs({ as: 'header' })`
  max-width: 1112px;
  display: flex;
  align-items: center;
  padding-top: 38px;
  padding-bottom: 38px;
`;

const NavItem = styled.li`
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: -0.08rem;
  margin-right: 24px;

  ${mediaSelector.hoverSupported} {
    > a:hover {
      text-decoration: underline;
    }
  }
`;

const SignInNavItem = styled(NavItem)`
  ${mediaSelector.mobile} {
    display: none;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const Right = styled.div`
  margin-left: auto;
`;

const StyledLogoIcon = styled(LogoIcon)`
  margin-right: 24px;

  path {
    fill: ${p => p.theme.currentTheme.text};
  }
`;

const SignUpButton = styled(Button)`
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: -0.08rem;
  padding: 0 23px;
  height: 43px;
  border-radius: 38px;

  a {
    color: ${p => p.theme.colors.white};
  }
`;

export default function Header() {
  const { t } = useTranslation('about');
  const currentUser = getCurrentUser();

  return (
    <StyledContainer>
      <Link href="/">
        <a>
          <StyledLogoIcon />
        </a>
      </Link>
      {/* <MobileHidden>
        <nav>
          <NavList>
            <NavItem>
              <Link href="/">
                <a>{t('header.mobileApp')}</a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/">
                <a>{t('header.pricing')}</a>
              </Link>
            </NavItem>
          </NavList>
        </nav>
      </MobileHidden> */}
      <Right>
        {currentUser ? (
          <Link href="/">
            <a>
              <Userpic
                size={48}
                title={currentUser.name}
                image={currentUser.profileImage}
                isHoverable={true}
              />
            </a>
          </Link>
        ) : (
          <nav>
            <NavList>
              <SignInNavItem>
                <Link href="/signin">
                  <a>{t('header.login')}</a>
                </Link>
              </SignInNavItem>
              <li>
                <SignUpButton primary={true}>
                  <Link href="/signin">
                    <a>{t('header.getStarted')}</a>
                  </Link>
                </SignUpButton>
              </li>
            </NavList>
          </nav>
        )}
      </Right>
    </StyledContainer>
  );
}
