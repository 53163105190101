import React, { PropsWithChildren } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

export const Container = styled.div``;

const Text = styled.div`
  font-size: 2.6rem;
  line-height: 3.1rem;
  letter-spacing: -0.1rem;
  margin-bottom: 16px;

  > strong {
    font-weight: 500;
  }
`;

const QuoteImage = styled(Image).attrs({ width: 40, height: 40 })`
  border-radius: 50%;
  margin: 3px 9px 0 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: inline-block;
`;

const Author = styled.div``;

const Info = styled.div`
  margin-left: 13px;
  font-size: 1.8rem;
  line-height: 1.94rem;
  padding-top: 3px;
  display: inline-block;
`;

const Name = styled.div`
  ${p => p.theme.fonts.titleSemibold}
`;

const JobTitle = styled.div`
  color: ${p => p.theme.currentTheme.notes};
`;

type Props = {
  author: string;
  post: string;
  image: string;
  children: React.ReactNode;
};

export default function Quote({ author, post, image, children }: PropsWithChildren<Props>) {
  return (
    <Container>
      <Text>{children}</Text>
      <Author>
        <QuoteImage src={image} alt={author} width={40} height={40} />
        <Info>
          <Name>{author}</Name>
          <JobTitle>{post}</JobTitle>
        </Info>
      </Author>
    </Container>
  );
}
