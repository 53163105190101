import React from 'react';
import { useTranslation } from 'lib/i18n';

import Link from 'next/link';
import { mediaQuery } from 'helpers/style-utils';

import styled from 'styled-components';

import XIcon from 'components/shared/svg/XIcon.svg';
// import InstagramIcon from 'components/shared/svg/InstagramIcon.svg';
import { Button } from 'client/components/shared';
import AboutBlock from '../AboutBlock';

const StyledAboutFooter = styled(AboutBlock)`
  background-color: ${p => p.theme.currentTheme.body};
  padding: 60px 0 34px 0;
`;

const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  ${mediaQuery(660)`
    flex-direction: column-reverse;
    align-items: center;
  `}
`;

const Copyright = styled.p`
  font-size: 1.8rem;
  line-height: 2.1rem;
  white-space: nowrap;
`;

const Items = styled.ul`
  display: flex;
  padding: 0;

  ${mediaQuery(500)`
    width: 100%;
  `}

  ${mediaQuery(450)`
    display: block;
  `}

  ${mediaQuery(660)`
    justify-content: center;
  `}
`;

const Item = styled.li`
  list-style-type: none;
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin: 0 9px 0 16px;
  white-space: nowrap;

  ${mediaQuery(450)`
    margin-bottom: 15px;
    text-align: center;
  `}
`;

const IconItem = styled(Item)`
  margin: 0 0 0 10px;
`;

const FollowButton = styled(Button)`
  font-size: 20px;
  padding: 0 10px 0 25px;
  height: 46px;

  svg {
    margin-left: 10px;
  }

  ${mediaQuery(660)`
    margin-bottom: 35px;
  `}
`;

const AboutFooter = () => {
  const { t } = useTranslation('about');
  const year = new Date().getFullYear();

  return (
    <StyledAboutFooter>
      <Container>
        <Items>
          <Item>
            <Copyright>{t('footer.copyright', { year })}</Copyright>
          </Item>
          <Item>
            <Link href="/tos/privacy">
              <a>{t('footer.privacy')}</a>
            </Link>
          </Item>
          {/*
            <Item>
              <Link href="/tos/privacy-policy">
                <a>{t('footer.cookies')}</a>
              </Link>
            </Item>
          */}
          <Item>
            <Link href="/tos">
              <a>{t('footer.tos')}</a>
            </Link>
          </Item>
          {/*
            <IconItem>
            <a href="#TODO" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
            </IconItem>
            */}
        </Items>
        <IconItem>
          <a href="https://twitter.com/columnsapp" target="_blank" rel="noopener noreferrer">
            <FollowButton primary={true} rounded={true}>
              Follow on
              <XIcon />
            </FollowButton>
          </a>
        </IconItem>
      </Container>
    </StyledAboutFooter>
  );
};

export default AboutFooter;
