import React, { PropsWithChildren } from 'react';

import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';
import { Container } from './styles';

// prettier-ignore
const StyledAboutBlock = styled.div`
  padding: 60px 0;
`;

export const Inner = styled(Container)`
  transition: padding 0.1s;

  ${mediaSelector.mobile} {
    padding: 0 20px;
  }
`;

export default function AboutBlock({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <StyledAboutBlock className={className}>
      <Inner>{children}</Inner>
    </StyledAboutBlock>
  );
}
