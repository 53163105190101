import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'lib/i18n';

const Container = styled.div`
  font-size: 2.8rem;
  line-height: 3.3rem;
  letter-spacing: -0.04em;
  text-align: center;
  padding: 62px 0 72px;
`;

export default function UseColumnsBlock() {
  const { t } = useTranslation('about');

  return <Container>{t('useColumnsBlock.title')}</Container>;
}
