import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
// @ts-ignore FIXME
import Swiper from 'react-id-swiper';
import { useTranslation, Trans } from 'lib/i18n';

import { mediaQuery } from 'client/helpers/style-utils';

import ArrowIcon from 'components/v2/shared/svg/24/ArrowIcon.svg';
import { shuffle } from 'lodash';
import Quote, { Container as QuoteContainer } from './Quote';

import { Container, TitleText, Description } from '../styles';

const Quotes = styled.div`
  position: relative;
  margin-top: 68px;

  ${mediaQuery(1000)`
    margin-left: -30px;
    margin-right: -30px;

    ${QuoteContainer} {
      padding: 0 30px;
    }
  `}

  .SwiperContainer {
    height: 100%;
    width: 100%;
  }
`;

const StyledDesctiption = styled(Description)`
  margin-top: 73px;
  line-height: 2.27rem;
`;

const Slide = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: -44px;
  top: -1px;
  width: calc(100% + 85px);

  ${mediaQuery(1000)`
    display: none;
  `}
`;

const SliderButton = styled.div<{ isHidden?: boolean }>`
  cursor: pointer;
  visibility: ${p => (p.isHidden ? 'hidden' : 'visible')};
`;

const NextSlideIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
`;

type Quote = {
  image: string;
  author: string;
  post: string;
  text: string;
};

export default function QuotesBlock() {
  const { t } = useTranslation('about');
  const swiperRef = useRef();
  const [, setCurrentSlideIndex] = useState(0);
  const quotes = useMemo(() => {
    return shuffle(t('quotesBlock.quotes')) as Quote[];
  }, []);

  const onNextSlideClick = () => {
    // @ts-ignore
    if (swiperRef.current?.swiper) {
      // @ts-ignore
      swiperRef.current.swiper.slideNext();
      // @ts-ignore
      setCurrentSlideIndex(swiperRef.current?.swiper?.realIndex);
    }
  };

  const onPrefSlideClick = () => {
    // @ts-ignore
    if (swiperRef.current?.swiper) {
      // @ts-ignore
      swiperRef.current.swiper.slidePrev();
      // @ts-ignore
      setCurrentSlideIndex(swiperRef.current?.swiper?.realIndex);
    }
  };

  const onSlideChange = () => {
    // @ts-ignore
    if (swiperRef.current?.swiper) {
      // @ts-ignore
      setCurrentSlideIndex(swiperRef.current?.swiper?.realIndex);
    }
  };

  return (
    <Container>
      <TitleText>
        <Trans i18nKey="quotesBlock.title" t={t} />
      </TitleText>
      <Quotes>
        <Buttons>
          {/* @ts-ignore */}
          <SliderButton isHidden={swiperRef.current?.swiper?.isBeginning}>
            <ArrowIcon onClick={onPrefSlideClick} />
          </SliderButton>
          {/* @ts-ignore */}
          <SliderButton isHidden={swiperRef.current?.swiper?.isEnd}>
            <NextSlideIcon onClick={onNextSlideClick} />
          </SliderButton>
        </Buttons>
        <Swiper
          ref={swiperRef}
          slidesPerView={3}
          spaceBetween={40}
          shouldSwiperUpdate={true}
          on={{
            slideChange: onSlideChange,
          }}
          breakpoints={{
            1000: {
              slidesPerView: 2,
            },
            600: {
              slidesPerView: 1,
            },
          }}
        >
          {quotes.map((quote, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Slide key={index}>
              <Quote author={quote.author} post={quote.post} image={quote.image}>
                <Trans i18nKey={quote.text} t={t} />
              </Quote>
            </Slide>
          ))}
        </Swiper>
      </Quotes>
      <StyledDesctiption>
        <Trans i18nKey={t('quotesBlock.subTitle')} t={t} />
      </StyledDesctiption>
    </Container>
  );
}
