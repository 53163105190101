import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'lib/i18n';

import { mediaQuery } from 'client/helpers/style-utils';
import { staticUrl, contextUrl } from '../lib/utils';

import { TitleText, Container } from '../styles';
import Feature from './Feature';

const FeaturesContainer = styled.div`
  margin-top: 48px;
`;

const StyledTitleText = styled(TitleText)`
  margin-bottom: 55px;

  em {
    font-style: normal;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 5px;
      right: -5px;
      left: 0px;
      height: 5px;
      z-index: -1;
      border-radius: 5px;
      background: ${p => p.theme.colors.dodgerBlue};
    }
  }
`;

const SubTitleText = styled(TitleText)`
  em {
    font-style: normal;
    color: ${p => p.theme.colors.dodgerBlue};
  }
`;

const ExamplesTitle = styled.h2`
  font-size: 10rem;
  line-height: 10rem;
  letter-spacing: -0.04rem;
  margin: 65px 0 60px;

  ${mediaQuery(850)`
    font-size: 8rem;
    line-height: 6rem;
    letter-spacing: -0.4rem;
  `}

  ${mediaQuery(650)`
    font-size: 7rem;
    line-height: 5rem;
    letter-spacing: -0.3rem;
  `}
`;

const FeaturesWrapper = styled.div``;

const FEATURES_LOCALE_KEYS = ['movies', 'roadmap', 'recipes', 'teamwork', 'diet'];

export default function FeaturesBlock() {
  const { t } = useTranslation('about');

  return (
    <FeaturesContainer>
      <Container>
        <StyledTitleText>
          <Trans i18nKey="featuresBlock.title" t={t} />
        </StyledTitleText>
        <SubTitleText>
          <Trans i18nKey="featuresBlock.subTitle" t={t} />
        </SubTitleText>
        <ExamplesTitle>{t('featuresBlock.examplesTitle')}</ExamplesTitle>
      </Container>
      <FeaturesWrapper>
        {FEATURES_LOCALE_KEYS.map(key => (
          <Feature
            key={key}
            title={`features.${key}.title`}
            text={`features.${key}.text`}
            screenshot={
              <img
                src={staticUrl('features-chat.png', contextUrl.ABOUT)}
                width="960"
                height="504"
                alt=""
              />
            }
          />
        ))}
      </FeaturesWrapper>
    </FeaturesContainer>
  );
}
